import React, {  useEffect } from 'react'
 import { useParams, Link } from 'react-router-dom';
import  { fetchEForms, fetchForms, fetchOrganizations }  from "../store/actions/dataCollected"; 
 import Skeleton from 'react-loading-skeleton'
 import { useSelector, useDispatch } from "react-redux";
  
const EForms = () => {
    const { organizations, eForms } = useSelector((state) => state?.organization); 
   var dispatch = useDispatch()
     const loadDataOnlyOnce = React.useCallback(() => { 
   dispatch(fetchEForms())
    dispatch({type:'ON_FORM_DATA_SUBMITED'})
    dispatch(fetchOrganizations())
  }, []);

  useEffect(() => {
    loadDataOnlyOnce();
  }, [loadDataOnlyOnce]);

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Add smooth scrolling behavior
    });
  };
 
 
// Loader skeleton
if(!eForms.length > 0){
  return (
    <div className="row" style={{margin:24}}>
       <div className="">
      <div className="card mb-4">
        <div className="card-header d-flex align-items-center justify-content-between">
            <h5 className="mb-0">Loading e-forms, just a moment...</h5>
            <small className="text-muted float-end">
            <div className="spinner-border spinner-border-sm text-primary" role="status"></div>
            <span className="visually-hidden">Loading, just a moment...</span>
            </small>
        </div>
        <div className="" style={{paddingLeft:24, paddingRight:24, paddingBottom:16}}>
        <Skeleton  height={'74px'} style={{margin:8, width:74}}/> 
        <Skeleton count={5} height={'44px'} style={{margin:8}}/> 
        </div>
      </div> 
      </div>
    </div>
)
}
 
 

  return ( 
              <div className="layout-demo-wrapper">
              <div className="container-xxl layout-demo-info"> 
                  <div className="row"> 
                 <div className="col-xxl">
                  <div className="card mb-4"> 
                    <div className="order-2 mb-4">
                    <div className="card-header d-flex align-items-center justify-content-between">
                      <h3 className="card-title m-0 me-2"> E forms</h3>
                      
                    </div>
                  <div className=" h-100">
                    <div className="card-body ">
                   
                      <ul className="p-0 m-0 demo-inline-spacing">
                        {
                          eForms && eForms.map((form, idx)=>{
                            let organization = organizations && organizations.find((o) => o.id === form.organizationId)
                             idx++
                            if(idx<21){
                             return (
                              <Link to={"/ecollect/" + form.uid} onClick={handleClick} className="d-flex mb-4 pb-1" key={`${idx}`} style={{cursor:'pointer'}}>
                              <div className="flex-shrink-0 me-3 avatar-initial rounded bg-label-secondary">
                                <img src={require("../assets/img/icons/unicons/wallet.png")} alt="User" className="rounded" />
                              </div>
                              <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                <div className="me-2">
                                <h4 className=" ">{organization?.name }</h4>
                                  <h6 className="mb-0">{form && form.name}</h6>
                                  <small className="text-muted d-block mb-1">{form && form.description}</small>
                                  {/* <span className="badge bg-label-primary rounded-pill">{organization?.name }</span> */}
                                </div>
                                <div className="user-progress d-flex align-items-center gap-1">
                                  {/* <h6 className="mb-0">813 Joined</h6> */}
                                  {/* <span className="text-muted">Due in 3 days</span> */}
                                </div>
                              </div>
                            </Link>
                            )}
                          })
                        } 
                      </ul>
                      {/* <div className="card-header d-flex align-items-center justify-content-between">
                      <h5 className="card-title m-0 me-2"><div  className="btn btn-outline-primary">Get a free trial</div></h5>
                      
                    </div> */}
                    </div>
                  </div>
                </div>
                  </div>
                </div>
              </div>
                </div> 
              </div> 
  );
}

export default EForms;

import React, {  useState, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group';
import './FAQ.css'; // Create a CSS file for styling transitions

const questions = [
  {
    question: "What services does Loop offer?",
    answer:
      "Loop specializes in data analytics, data management, and data consulting services. We help businesses make informed decisions based on comprehensive data analysis.",
  },
  {
    question: "How secure is the data stored by Loop?",
    answer:
      "We prioritize data security and implement industry-leading measures to safeguard the data we handle. We employ encryption, access controls, and regular security audits to ensure the utmost protection of our clients' data.",
  },
  {
    question: "Can Loop handle large-scale data projects?",
    answer:
      "Absolutely! Loop is equipped to handle large-scale data projects. We have the infrastructure, expertise, and scalability to manage and analyze vast amounts of data efficiently.",
  },
  {
    question: "What industries does Loop serve?",
    answer:
      "We serves a wide range of industries, including finance, healthcare, e-commerce, marketing, and manufacturing. We tailor our solutions to meet the specific data needs of each industry.",
  },
  {
    question: "Do you offer customizable data solutions for businesses?",
    answer:
      "Yes, we provide customizable data solutions based on the unique requirements of each business. Our team works closely with clients to understand their goals and deliver tailored data solutions to meet their needs.",
  },
  {
    question: "Can Loop help with data integration from different sources?",
    answer:
      "Absolutely! We specialize in data integration from various sources, such as databases, APIs, spreadsheets, and external platforms. Our expertise ensures seamless data integration and consolidation for comprehensive analysis.",
  },
  {
    question: "How does Loop ensure data accuracy and quality?",
    answer:
      "We employ rigorous data cleansing and validation processes to ensure data accuracy and quality. Our team performs thorough data checks, implements data quality rules, and applies advanced techniques to maintain data integrity.",
  },
  {
    question: "Does Loop comply with data protection regulations?",
    answer:
      "Yes, we prioritize compliance with data protection regulations, such as GDPR and CCPA. We adhere to strict data governance practices and provide transparency regarding data usage, consent, and privacy.",
  },
  {
    question: "Can Loop help with predictive analytics and data modeling?",
    answer:
      "Absolutely! We have expertise in predictive analytics and data modeling. Our team leverages advanced algorithms and statistical techniques to uncover insights, patterns, and trends to support informed decision-making.",
  },
  {
    question: "How can businesses benefit from partnering with Loop?",
    answer:
      "By partnering with oLoop, businesses can gain a competitive edge through data-driven decision-making. We provide actionable insights, help optimize processes, identify growth opportunities, and drive business success through the power of data.",
  },
];

const Faq = () => {
   

  const [selectedQuestion, setSelectedQuestion] = useState(null);

  
  const handleQuestionClick = (questionId) => {
    if (selectedQuestion === questionId) {
      setSelectedQuestion(null);
    } else {
      setSelectedQuestion(questionId);
    }
  }; 
  return(
    <div class="content-wrapper">
 
            <div class="container-xxl flex-grow-1 container-p-y">
              <h4 class="fw-bold py-3 mb-4"><span class="text-muted fw-light">Faq's /</span> Freaquently asked questions</h4>

              <div class="col-md mb-4 mb-md-0">
                  <small class="fw-semibold">NB: If you do not see an answer here, kindly contact our tech support team at support@loop.com.na</small>
                  <div class="accordion mt-3" id="accordionExample">
                      {questions.map((question) => (
                          <div  key={question.id} class="faq-question card accordion-item">
                          <h5 class="accordion-header question" id="headingOne" onClick={() => handleQuestionClick(question.id)}>
                            <button type="button" class="accordion-button collapsed open" data-bs-toggle="collapse" data-bs-target={`#${question.id}`} aria-expanded={selectedQuestion === question.id} aria-controls="accordionOne">
                            {question.question}
                            </button>
                          </h5>
                            {
                              // selectedQuestion === question.id &&
                              // <CSSTransition id="accordionOne" 
                              // in={selectedQuestion === question.id}
                              //     classNames="answer"
                              //     timeout={300}
                              //     unmountOnExit
                              //   >
                                  <div id={`#${question.id}`} class="accordion-collapse collapse show" aria-labelledby="headingThree" data-bs-parent="#accordionExample" >
                                    <div class="accordion-body">
                                    {question.answer}
                                    </div>
                                  </div> 
                              // </CSSTransition>
                            }
                        </div> 
                    ))} 
                  </div>
                </div> 
            </div>
  
 
            <div class="content-backdrop fade"></div>
          </div>
  )
}
 
  

export default Faq;

/* eslint-disable import/no-anonymous-default-export */
 
var initialState = {
  forms:[],
  form: null,
  
  dataCollected:[],
  dataCollectedExtras:{time:0},
  dataCollection:[],

  formSubmitted:true,
  userLocation:null,
  formReady:false
  }


export default function (state = initialState, action) {
  const { type } = action;
   switch (type) {
 
    case 'LOCATION':
       return {
    ...state,
    userLocation : action.payload
    };

     case "ON_FORM_LOADED":
        return  {
        ...state, 
        form:action.payload,
      }


      case "ON_ALL_FORMS_LOADED":
        return  {
        ...state, 
        forms:action.payload
      }


    case "ON_FORM_VALUE_UPDATED": 
      var  { uid, value } = action.payload
      let array =  state.dataCollected
      const i = array.findIndex( item => {return item.uid === uid});
      if (i > -1) array[i] = { uid, value }; // (2)
      else array.push({ uid, value });

        return  {
          ...state,
            dataCollected: array
          }
      case "ON_EXTRA_FORM_VALUE_UPDATED": 
          let { key, val} = action.payload
          let copyOfdataCollectedExtras = state.dataCollectedExtras
          copyOfdataCollectedExtras[key] = val
         return  {
          ...state,
            dataCollectedExtras: copyOfdataCollectedExtras
          }

    case "ON_FORM_DATA_SUBMITED": 
              return  {
              ...state,
              dataCollected:[],
              }

    case "ON_FORM_DATA_READY": 
      return  {
        ...state,
        formReady:action.payload.formReady,
        }



  
        
    default:
      return { ...state }

   }
}


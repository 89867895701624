import React, {  useEffect } from 'react'
 import { useParams, Link } from 'react-router-dom';
import  { fetchForms }  from "../store/actions/dataCollected"; 
import  { addForm }  from "../store/actions/dataCollected";
import Skeleton from 'react-loading-skeleton'
import { Star } from 'react-star';
import { useSelector, useDispatch } from "react-redux";
 
 
var API = process.env.NODE_ENV === "development" ? "http://0.0.0.0:5000/api/demo" : `https://api-prod.loop.com.na/api/demo`

const RequestDemo = () => {
    var [ email, setEmail] = React.useState(null)
    var [ name, setName] = React.useState(null)
    var [ company, setCompany] = React.useState(null)
    var [ message, setMassage] = React.useState(null)
    var [ product, setProduct] = React.useState(null)

    var [ msg, setMsg] = React.useState('Request succesfully submitteb!, talk to you soom')
    var [ submited, setSubmited] = React.useState(false)
    var [ err, setErr] = React.useState(false)
    var [ loading, setLoading] = React.useState(false)

  const submitEData = async () => { 
    // const fullN = `${name}, ${company}`
    let formData = {
      name:name,
      email,
      option:product,
      message, 
      company,
      source: "E data website - forms.loop.com.na"
  };
  const isEmpty = Object.values(formData).some(x => x === null || x.trim().length ===0 || x.trim() === '')
  if(isEmpty){
    setMsg('All fields are required')
    setSubmited(true)
    setErr(true)
    setTimeout(() => {
      setSubmited(false)
      setErr(false)
    }, 4000);

  }
  else{
    setLoading(true)
    formData['name'] = `${name} from ${company}`
    try {   fetch(`${API}/add`, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            }
            ,
            body:  (JSON.stringify(formData))
        })
        .then((response) => JSON.stringify(response) )
        .then((json) => {
          setProduct(null)
          setEmail(null)
          setCompany(null)
          setName(null)
          setMassage(null)
          setSubmited(true)
          setLoading(false)
          setTimeout(() => {
            setSubmited(false)
          }, 5000);
            window.location.reload() 
        })
        .catch((error) => {
          setMsg('Request failed!, kindly contact our sales team at sales@loop.com.na')
          setSubmited(true)
          setErr(true)
          setLoading(false)
          setTimeout(() => {
            setSubmited(false)
            setErr(false)
          }, 15000);
          // console.error(error);
        })
      } catch (error) {
        setLoading(false)
        setMsg('There was an error proccesing your request, kindly contact our sales team at sales@loop.com.na')
        setSubmited(true)
        setErr(true)
        setTimeout(() => {
          setSubmited(false)
          setErr(false)
        }, 4000);
     } 
  }
  }
 
  return(
    <div className='row'>
    <div className="col-9 col-md-8 col-12 mb-4  " >
        <div className="authentication-inner ">
           <div className="card">
            <div className="card-body"> 
               <h4 className="mb-2">Request a demo</h4> 
              <p className="mb-4">Our exprreriende sales teem we'll conact you soon</p>
              {
                submited && !err && <p className="mb-4 text-success">{msg}</p>
              }
              {
                err && <p className="mb-4 text-danger">{msg}</p>
              }
              
              <div id="formAuthentication" className="mb-3"  >
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">Full name</label>
                  <input type="name" value={name} onChange={(e)=>setName(e.target.value)} className="form-control" id="name" name="name" placeholder="Enter your name" autoFocus="" required/>
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Email address</label>
                  <input type="email" value={email} onChange={(e)=>setEmail(e.target.value)} className="form-control" id="email" name="email" placeholder="Enter your email" autoFocus="" required/>
                </div>
                <div className="mb-3">
                  <label htmlFor="company" className="form-label">Company name</label>
                  <input type="company" value={company} onChange={(e)=>setCompany(e.target.value)} className="form-control" id="company" name="name" placeholder="Enter your company name" autoFocus="" required/>
                </div>
                <div className="mb-3">
                  <label htmlFor="option" className="form-label">Product</label>
                  <div className="c">
                    <select value={product} onChange={(e)=>setProduct(e.target.value)} name="option" required className=" form-select" id="exampleFormControlSelect1" aria-label="Default select example">
                      <option value="">Select product</option>
                      <option value="E - Data">E - Data</option>
                      <option value="Digital signage">Digital signage</option>
                      <option value="Queue Management">Queue Management</option>
                      <option value="CRM">Customer relationship management</option>
                      <option value="Feedback">Feedback</option>
                      <option value="Self service">Self service</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>
                 </div>
                <div className="mb-3">
                  <label htmlFor="massage" className="form-label">Massage</label>
                  <textarea value={message} id="message" onChange={(e)=>setMassage(e.target.value)} name="message" className="form-control" placeholder="Hi, Do you have a moment to talk Joe?" aria-label="Hi, Do you have a moment to talk Joe?" aria-describedby="basic-icon-default-message" required></textarea>
                  {/* <textarea type="massage" onChange={setEmail} className="form-control" id="massage" name="massage" placeholder="Enter your email" autoFocus=""/> */}
                </div>
                <button
                  onClick={ ()=> submitEData()}
                   className="btn btn-primary me-2"

                   disabled={ loading  }   
                   >
                     { 
                       loading && (
                       <span className="spinner-border spinner-border-sm"></span>
                     )
                   }
                    &nbsp; {!loading ? 'Send request' : 'Sending request'}</button>
              </div>
              <div className="text-center">
                <Link to="/" className="d-flex align-items-center justify-content-center">
                  <i className="bx bx-chevron-left scaleX-n1-rtl bx-sm"></i>
                  Back to main page
                </Link>
              </div>
            </div>
          </div>
          
         </div>
      </div>
      <div class="col-3 col-md-4 col-12 mb-4">
                      <div class="card">
                      <img class="card-img-top" src={require('../assets/img/edata.png')} alt="Card cap" /> 
                         <div class="card-body">
                          <h5 class="card-title">Introducing E-DATA</h5>
                          <p class="card-text">
                            Sign Up now to reduce operational cost by 75% and Unlock the Full Potential of customer data. 
                          </p>
                          <a href={'https://loop.com.na'} rel="noreferrer" style={{backgroundColor: "#115799", borderColor:  "#115799" }}  class="btn btn-primary">Get started now</a>
                        </div>
                      </div>
                    </div>
    </div>
  )
}
 
  

export default RequestDemo;

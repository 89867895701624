import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import organization from "./organization";
import dataCollection from "./dataCollection";


export default combineReducers({
  auth,
  message,
  organization,
  dataCollection
});

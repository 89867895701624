import React, {  useEffect } from 'react'
 import { useParams, Link } from 'react-router-dom';
import  { fetchForms }  from "../store/actions/dataCollected"; 
import  { addForm }  from "../store/actions/dataCollected";
import Skeleton from 'react-loading-skeleton'
import { Star } from 'react-star';
import { useSelector, useDispatch } from "react-redux";
 
 

const ThankYou = () => {
   

 
  return(
    <div className="row">
      <div className="col-lg-8 mb-4 order-0">
        <div className="card">
          <div className="d-flex align-items-end row">
            <div className="col-sm-7">
              <div className="card-body">
                <h5 className="card-title text-primary">Thank you! </h5>
                <p className="mb-4">
                  We wish to wish you soon
                </p>

                <div className="btn btn-sm btn-outline-primary">Join mailing list</div>
              </div>
            </div>
            <div className="col-sm-5 text-center text-sm-left">
              <div className="card-body pb-0 px-0 px-md-4">
                <img src={require("../assets/img/illustrations/man-with-laptop-light.png")}  height="140" alt="View Badge User" data-app-dark-img="illustrations/man-with-laptop-dark.png" data-app-light-img="illustrations/man-with-laptop-light.png"/>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
  )
}
 
  

export default ThankYou;

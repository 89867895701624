/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-unused-vars */
  
var initialState = {
    organization:null,
    users:[],
    user:[],
    forms:[],
    form:{},
    formSubmitted:false,
    
    alert: { 
      type:'', 
      message:'', 
      extras:{} 
    },
    isUploading:false,
    isSurveyComplete:false,
    
    eForms:[],
    organizations:[],
    isSubmitting:false
  }


export default function (state = initialState, action) {
  const { type, payload } = action;
   switch (type) {
    case 'ON_ORGANIZATION_SUCCESS':
          return {
        ...state,
        organization :payload
        };
 
    case 'ON_ORGANIZATION_FAIL':
      return {
        ...state,
       };
 
    case 'ON_DATA_LOAD':

    return {
      ...state,
      form:action.payload,
       };

    case 'ON_MORE_FORMS_LOADED': 
    return {
      ...state,
      forms:action.payload
      };

    case 'ON_SHOW_NEW_FORM': 
    const newForm = state.forms.map((form)=>form.id===action.payload)
    return {
      ...state,
      form:newForm
    };

    case "SUBMITTING_FORM":
    return  {
      ...state,
      isSubmitting: true
    }

  case "ON_FORM_DATA_SUBMITED":  
    return  {
      ...state,
      form:{},
      formSubmitted: true
    }
        
  case "ON_THANK_YOU_END":  
    return  {
      ...state,
      form:{},
      formSubmitted:false
    }

  case 'SHOW_NOTIFICATION':
    let { type, message, title } = action.payload
    return {
      ...state,
      alert: { type, title, message, show:true }
    };

  case 'HIDE_NOTIFICATION':
      return {
      ...state,
      alert: { type:'', title:'', message:'', show:false }
    };

  case 'IS_UPLOADING_E_DATA':
    return {
    ...state,
    isUploading: true
  };

  case 'IS_UPLOADING_E_DATA_DONE':
    return {
    ...state,
    isUploading: false,
    isSurveyComplete:true
  };

  case "ON_ALL_ELECTRONIC_FORMS": 
    return  {
      ...state,
      eForms:action.payload,
      }

  case 'ON_ALL_ORGNIZATIONS_LOADED':
    return {
    ...state,
    organizations: action.payload,
   };



    default:
      return state;
  }
  
}

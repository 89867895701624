import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
 } from "react-router-dom";
// import 'boxicons';
import "./assets/vendor/css/core.css";
import "./assets/vendor/css/theme-default.css";
import "./assets/vendor/css/pages/page-auth.css";
import 'react-loading-skeleton/dist/skeleton.css'

import Layout from './pages/Layout' 
import Form from './pages/Form'
import EForms from './pages/EForms' 
import RequestDemo from './pages/RequestDemo'//
import Examples from './pages/Examples'
import Faq from './pages/Faq'

import ThankYou from './pages/ThankYou'
 

const App = () => {  
  return (
    <Router >
        {/* <Layout> */}
            <Routes>
                  <Route exact path={"/e-forms"}  element={<Layout><EForms/></Layout>} />
                  <Route exact path={"/"}  element={<Layout><EForms/></Layout>} />
                 <Route exact path={"ecollect/:formId"}  element={<Layout><Form/></Layout>} />
                 <Route exact path={"/thank-you"}  element={<Layout><ThankYou/></Layout>} />
                {/* <Route exact path={"/"}  element={<Form/>} />  */}
                  <Route exact path={"/embbed/:formId"}  element={<Form/>} />
                  <Route exact path={"/demo"}  element={<Layout><RequestDemo/></Layout>} />
                  <Route exact path={"/solutions"}  element={<Layout><Examples/></Layout>} />
                  <Route exact path={"/faqs"}  element={<Layout><Faq/></Layout>} />
                  
          </Routes>
        {/* </Layout> */}

     </Router>
  );
};

export default App;

import { doc, addDoc,  onSnapshot, updateDoc, collection, deleteDoc, where, query, getDocs, getDoc } from "firebase/firestore";
import { db } from "../../utils/firebase";
import {uploadImage} from  "../../utils/uploadImage";
import DeviceDetector from "device-detector-js";
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from "../../utils/firebase";
import Strings from '../../utils/Strings'
const axios = require('axios').default;


 
const {  v4: uuidv4 } = require('uuid');
const dayjs = require('dayjs')
var now = dayjs().format()
var created = dayjs(now).unix()
const deviceDetector = new DeviceDetector();

var API = process.env.NODE_ENV === "development" ? "http://0.0.0.0:5000/api/e-data" : `https://api-prod.loop.com.na/api/e-data`

export const fetchEForms = () => async (dispatch) => { 
     const devicesQuery = query(collection(db, "appData/data-collection/forms") )
       onSnapshot(devicesQuery, async (querySnapshot) => { 
         var eForms  = [] 
         querySnapshot.forEach((doc) => {
           let data = doc.data()
           data.id = doc.id
           
           eForms.push(data)
           
          });
          console.log("forms", eForms);
          var forms  = eForms.filter((form)=>{
            if(form && form?.active){ 
               return form
            }
          })
          dispatch({type:"ON_ALL_ELECTRONIC_FORMS", payload:forms})
     }, (error) => { });
};

export const fetchOrganizations = () => async (dispatch) => { 
  const devicesQuery = query(collection(db, "/organizations"), where('active', '==', true))
    onSnapshot(devicesQuery, async (querySnapshot) => { 
      var organizations  = [] 
      querySnapshot.forEach((doc) => {
        let data = doc.data()
        data.id = doc.id
        organizations.push(data)
      });
       dispatch({type:"ON_ALL_ORGNIZATIONS_LOADED", payload:organizations})
  }, (error) => { });
};


export const fetchDataCollected = (organizationId) => async (dispatch) => {
  
  
      if(organizationId!=="" && organizationId !==null){
      const response = await fetch(API, {method: 'GET', organizationId})
      await response.json()
      .then(response =>{
        if(response !== null && response.status){
          var { formData } = response 
          dispatch({type:'ON_DATA_COLLECTED_SUCCESS', payload: formData })           
          }
      })
      .catch(error =>{
        // write error to logs
          console.log(error)
      }) 
    } 
};

export const fetchForms = (formId) => async (dispatch) => {

    // FORMS
    const q = query(collection(db, "appData/data-collection/forms"), where("uid", "==", formId),  )

    const querySnapshot = await getDocs(q);
    var form = {}
      querySnapshot.forEach((doc) => {
        form = { id:doc.id, ...doc.data() }
            dispatch({type:"ON_DATA_LOAD", payload:form})
      }) 
        const { organizationId } = form

          const organizationRef = doc(db, "organizations", organizationId)
        const organizationSnap = await getDoc(organizationRef)
        if (organizationSnap.exists()) {
          let data = organizationSnap.data()
          data.id = organizationSnap.id
          dispatch({type:"ON_ORGANIZATION_SUCCESS", payload:data})
          } else { dispatch({type:"ON_ORGANIZATION_FAIL"}) }

          const formsQuery = query(collection(db, "appData/data-collection/forms"), 
          where("organizationId", "==", organizationId))
          onSnapshot(formsQuery, async  (querySnapshot) => { 
            var forms  = [] 
              querySnapshot.forEach((doc) => {
                let data = doc.data()
                data.id = doc.id
                forms.push(data)
              });
              
              forms  = forms.filter((form)=>{
              if(form && form?.active){ 
                  return form
              }
            })
              dispatch({type:"ON_MORE_FORMS_LOADED", payload:forms})
            }, (error) => { }); 
        
  };



  // Submit collected e-data
  async function submitEData (formData, organization){
  var { form } = organization
  const { organizationId, id } = form
  const uuid = uuidv4()
   const { time } = formData
  delete formData['time']
     try {
       const convertedData = Object.values(formData).reduce((acc, item) => {
        let key = item.uid.replace(/-/g, '_')
        acc[key] = item.value;
        return acc;
      }, {});
       const payload = {
        columns: {
          ...convertedData,
          id: uuid.replace(/-/g, '_'),
          organizationId,
          formId:id,
          created,
          time: time || 0,
          linked:0,	
          linked_to:"",	
          updated: 
          created
        },
        name:`${id}`,
      };
      // return null
       const endpointUrl = `${Strings.LOOP_SERVER}/api/v2/e-data`;   
      await fetch(`${endpointUrl}`, {
        method: "POST",
        mode: 'cors',
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => JSON.stringify(response))
        .then((json) => {
          submitTrigger({data:payload, form, organization})
          setTimeout(() => {
            
            window.location.reload()
          }, 2000);
          // console.log(json);
          return json;
        })
        .catch((error) => {
          console.error(error);
         });
    } catch (err) {
      return (err);
    }
 
} 

  // Submit e collected data
export const addForm = ()  => async (dispatch, getState) => {
 
  var { dataCollection, organization } = getState()
  var { form } = organization
  const { organizationId } = form
  var { time } = dataCollection.dataCollectedExtras
  
  const organizationData = {organizationId, time: 0, created, form }
  var newDataArray =  dataCollection?.dataCollected
   var fieldsWithFiles = form && form?.fields.filter(((field) =>  { return field?.type === 'documents' || field?.type === 'esign'}))
  if (fieldsWithFiles.length > 0) {
          async function files(item){ 
            return new Promise((resolve, reject) => {
              let them = dataCollection?.dataCollected.find((element)=>element.uid.replace(/-/g, '_')===item.uid)
                  return resolve(them)
          })}
          
          const promises = await fieldsWithFiles.map((item) => files(item));
           Promise.all(promises)
          .then((res) => {
              uploadAttachments(res, form, organizationData)
          })
          .catch((error) => {
            console.log('Replacement files with download URLs:', error);
          });
  }
  else{ 
    dispatch({  type: 'IS_UPLOADING_E_DATA' });
    newDataArray.time = time
    submitEData(newDataArray, organizationData) 
   }
 
  async function uploadAttachments   (el, form, organization) {
    dispatch({  type: 'IS_UPLOADING_E_DATA' });
    var docArray =  uploadImage(dataCollection?.dataCollected, form) 
    docArray.then( async (updatedObject)=>{
       if(updatedObject && updatedObject[0]){
        newDataArray = [...dataCollection?.dataCollected]
        var objIndex = newDataArray.findIndex((obj => obj.uid ===updatedObject[0].uid))
        newDataArray[objIndex]  = updatedObject[0]
        newDataArray.time = time
         submitEData(newDataArray, organization) 
       }  
  })
  .catch( error => dispatch({
    type:'ON_FORM_VALUE_UPDATED', payload: {
      uid:el.uid, value:[] 
    }
  }),
  )
  }

  
}




const submitTrigger = async (payload) => {
  console.log("submitTrigger", payload);
   if(payload){
     try { 
      await fetch(`${API}/add-trigger`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
        ,
        body: JSON.stringify(payload)
    })
    .then((response) => JSON.stringify(response) )
    .then((json) => { 
      window.location.reload()
      // window.history.pushState({}, null, '/e-forms');
     })
    .catch((error) => {
       console.error(error);
    })
  } catch (error) { 
}
  }
} 
  
 
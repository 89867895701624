import React, {  useEffect } from 'react'
 import { useParams, Link } from 'react-router-dom';
import  { fetchForms }  from "../store/actions/dataCollected"; 
import  { addForm }  from "../store/actions/dataCollected";
import Skeleton from 'react-loading-skeleton'
import { useSelector, useDispatch } from "react-redux";
import SignaturePad from 'react-signature-canvas'
import styles from './styles.module.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Star } from 'react-star';

 const fieldTypes = [
  { value: "text", default: "", name: "Text input" },
  { value: "longtext", default: "", name: "Paragraph input" },
  { value: "richtext", default: "", name: "Text editing input" },
  { value: "email", default: "", name: "Email input" },
  { value: "number", default: 0, name: "Number input" },
  { value: "verification", default: false, name: "Verification" },
  { value: "payment", default: 0.0, name: "Collect money" },
  { value: "rating", default: 0, name: "Rating" },
  { value: "select", default: "", name: "Dropdown list" },
  { value: "radiobutton", default: "", name: "Multiple choice" },
  { value: "multiselect", default: "", name: "Multiple selection" },
  { value: "date", default: 1684335080, name: "Date" },
  { value: "url", default: "", name: "URL or link" },
  { value: "range", default: 0, name: "Range" },
  { value: "esign", default: '', name: "Electronic signature" },
  { value: "documents", default: [], name: "Documents" },
];


const Form = (props) => {
   let { formId } = useParams();
   var { alert,  } = useSelector((state) => state.organization);
   var {dataCollected}  = useSelector((state) => state.dataCollection);
   const counterRef = React.useRef(0);

    var dispatch = useDispatch()
   const { organization, forms, form, formSubmitted, isUploading } = useSelector((state) => state?.organization);

  const loadDataOnlyOnce = React.useCallback(() => {
    dispatch(fetchForms(formId))
  }, [formId, dispatch]);

  useEffect(() => {
    loadDataOnlyOnce();
  }, [loadDataOnlyOnce]);
  
  const hideAlert = () => { dispatch({ type: 'HIDE_NOTIFICATION' })}
  React.useEffect(() => {
    const interval = setInterval(() => {
      counterRef.current += 1;
    }, 1000);
    return () => clearInterval(interval); // cleanup the interval on unmount
  }, []);

 
  const onChange = (uid, value) => {
    console.log("value", value);
     dispatch({type:'ON_FORM_VALUE_UPDATED', payload: {uid, value }})
  }


 const handleFileuploadChange = (uid, e) => {
  e.preventDefault()
  // setFile(e.target.files[0]); 
  onChange(uid, e.target.files[0])
  console.log("value", e.target.files[0]);
 }
 

  const { fields } = form;
  React.useEffect(() => {
    const fieldBuild = [];
    fields?.forEach((el) => {
      const defaultVal = fieldTypes.find(
        (field) => field.value === el.type,
      );
       let itemVal = defaultVal.default
      let attr = fields.find((e)=>e.uid===el.uid)
      if(el.type === "select"){
        itemVal = attr['options'][0]
       }
      else if(el.type === "radiobutton"){
        itemVal = attr['options'][0]
      }

      fieldBuild.push({
        name: el.uid.replace(/-/g, '_'),
        value: itemVal,
      });
      dispatch({type:'ON_FORM_VALUE_UPDATED', payload: {uid: el.uid.replace(/-/g, '_'), value: itemVal, }}) 
    });
   }, [fields, dispatch]);
  

 const submitFormData = (e) => {
   e.preventDefault()
    const myValidator = (element) => {
      return new Promise((resolve, reject) => {
        // Perform some asynchronous operation
        if (element?.conditions) {
          const { conditions } = element;
          if (conditions.required) {
            const defaultVal = fieldTypes.find(
              (field) => field.value === element.type,
            );
            const fieldData = dataCollected.find((el) => el.uid === element.uid.replace(/-/g, '_') );
            if (fieldData.value === defaultVal.default) {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
              });
              dispatch({
                type: 'SHOW_NOTIFICATION',
                payload: {  type:'danger', title:"Required",  message: "Fields marked with * are requried",  show:true  }
              });
              setTimeout(() => {
                dispatch({ type: 'HIDE_NOTIFICATION' })
              }, 3000)
              reject(true);
            } else {
              resolve(true);
             }
          }
          resolve(true);
        } else {
          resolve(true);
        }
      });
    };

    const promises = form.fields.map((item) => myValidator(item));
    Promise.all(promises)
      .then((res) => {
         dispatch({type:"ON_EXTRA_FORM_VALUE_UPDATED", payload:{key: 'time', val:counterRef.current}})
          dispatch(addForm());
      })
      .catch((error) => {
        dispatch({
          type: 'SHOW_NOTIFICATION',
          payload: {  type:'danger', title:"Required",  message: "An error occured, try again",  show:true  }
        });
        setTimeout(() => {
          dispatch({ type: 'HIDE_NOTIFICATION' })
        }, 3000)
      });
  
 }






 
 const onDateChange = (uid, dateString) =>{
   dispatch({
     type:'ON_FORM_VALUE_UPDATED', 
     payload: { uid, value: Math.floor(new Date(dateString).getTime() / 1000) }
   })
 } 


 const [trimmedDataURL, setTrimmedDataURL] = React.useState(null);
 const sigPadRef = React.useRef(null);

 const clear = () => {
   sigPadRef.current.clear();
 };

const trim = (uid, e) => {
 const dataURL = sigPadRef.current.getTrimmedCanvas().toDataURL('image/png');
 fetch(dataURL)
 .then(res => res.blob())
 .then(blob => {
  try {
    
    const file = new File([blob], "Signature",{ type: "image/png" })
    onChange(uid, (window.URL || window.webkitURL).createObjectURL(file))
  } catch (error) {
    
  }
 })
 .catch((error)=>{
   onChange(uid, '')
 })
};
var newFields = []
if(form.id) newFields  = [...form.fields]
 

if(isUploading){
  return <div className="row" style={{margin:24}}>
  <div className="col-xxl">
 <div className="card mb-4">
   <div className="card-header d-flex align-items-center justify-content-between">
       <h5 className="mb-0">Uploading e data, just a moment...</h5>
       <small className="text-muted float-end">
       <div className="spinner-border spinner-border-sm text-primary" role="status"></div>
       <span className="visually-hidden">Please wait...</span>
       </small>
   </div> 
 </div> 
 </div>
</div>
}
 
// Loader skeleton
if(!form?.id && !formSubmitted){
  return (
    <div className="row" style={{margin:24}}>
       <div className="col-xxl">
      <div className="card mb-4">
        <div className="card-header d-flex align-items-center justify-content-between">
            <h5 className="mb-0">Loading e-form, just a moment...</h5>
            <small className="text-muted float-end">
            <div className="spinner-border spinner-border-sm text-primary" role="status"></div>
            <span className="visually-hidden">Loading, just a moment...</span>
            </small>
        </div>
        <div className="" style={{paddingLeft:24, paddingRight:24, paddingBottom:16}}>
        <Skeleton  height={'74px'} style={{margin:8, width:74}}/> 
        <Skeleton count={5} height={'44px'} style={{margin:8}}/> 
        </div>
      </div> 
      </div>
    </div>
)
}
 
 

  return (
    <div className="">
        <div className="">
              <div className="layout-demo-wrapper">
              <div className="container-xxl layout-demo-info">
              {
                alert && alert.show ?
                  <div style={{position:"absolute", top:32, right:32}} className={`bs-toast toast fade show } position-absolute top-3 end-3 bg-${alert.type}`} role="alert" aria-live="assertive" aria-atomic="true">
                  <div className="toast-header">
                                <i className="bx bx-bell me-2"></i>
                                <div className="me-auto fw-semibold"> {alert?.title}</div>
                                {/* <small>11 mins ago</small> */}
                                <div style={{cursor:"pointer"}} onClick={hideAlert} className="btn-close" data-bs-dismiss="toast" aria-label="Close"></div>
                              </div>
                    <div className="toast-body">
                      {alert?.message}
                    </div>
                  </div>
                : null
              
              }
              <br/>
                   
                  <div className="row"> 
                  {
                    <div className="row">
                      <div className="col-9mb-4 ">
                  
                        <div className="card mb-4">
                          <div className="card-header d-flex align-items-center justify-content-center">
                            <div className="col">
                            <div className=" order-lg-2 order-1 align-self-end mb-4">
                              <div className="" style={{ textAlign: "left"}}>
                                <div className="justify-content-center">
                                <div className="col-sm-7  text-sm-right">
                                    <div className="">
                                      <img src={organization && organization?.logoUrl} height="40" alt={organization?.name} />
                                    </div>
                                  </div>
                                  <div className="col-sm-7">
                                    <div className="">
                                      <p className="">{organization && organization?.name}</p>
                                    </div>
                                  </div>
                                
                                </div>
                              </div>
                            <hr className="m-0"></hr>
                            </div>
                            <h3 className="mb-0">{form && form.name}</h3>
                            <p className="card-text">
                            {form && form.description}
                            </p>
                            </div>
                            {/* <small className="text-muted float-end">Powerd by loop technologies</small> */}
                          </div>
                          <div className="card-body">
                            <form _lpchecked="1" onSubmit={(e)=>submitFormData(e)}>
                            {/* <div className="card-body" onSubmit={submitFormData}>
                            <form _lpchecked="1" onSubmit={(e)=>e.preventDefault()}> */}
                              

                            {/* <FormFields />  */}
                            

                            {
                              newFields && newFields.map((field, index) => {
                                const val =  ""
                                 let suffix = "", { conditions } = field;
                                    if (conditions.required) {
                                      suffix = <span style={{color:"red", fontSize: '1rem',  justifyContent: 'center', alignItems: 'center'  }}> * </span>
                                     };
                                     let formattedField = field.uid.replace(/-/g, '_')
                                switch (field.type) {
                          
                                  case 'text':
                                    return(
                                      <div key={`${index}`} className=" mb-3">
                                      <label className="col-form-label" htmlFor="basic-default-name">{field.name}{ suffix }</label>
                                      <div className="col-sm-10">
                                        <input
                                          type={'text'}
                                          className="form-control" 
                                          id="basic-default-name" 
                                          placeholder={'john doe'}
                                          aria-label="john.doe"
                                          required={field.required || true}
                                          // value={val?.value }
                                          onChange={(event)=>onChange(formattedField, event.target.value, event)}
                                        />
                                      </div>
                                    </div>
                                      ) 
                                   
                                  case 'date':
                                      const date = new Date(val?.value * 1000);
                                    const year = date.getFullYear();
                                    const month = String(date.getMonth() + 1).padStart(2, '0');
                                    const day = String(date.getDate()).padStart(2, '0');
                                    const formattedDate = `${year}-${month}-${day}`;
                          
                                    return(
                                      <div key={`${index}`} className=" mb-3">
                                      <label className="col-form-label" htmlFor="basic-default-name">{field.name}{ suffix }</label>
                                      <div className="col-sm-10">
                                        <input
                                          type={'date'}
                                          className="form-control" 
                                          id="basic-default-name" 
                                          placeholder={'02 Jan 2020'}
                                          aria-label="john.doe"
                                          required={field.required || true}
                                          // value={formattedDate }
                                          onChange={(payload)=>onDateChange(formattedField, payload.target.value)}
                                        />
                                      </div>
                                    </div>
                                      )
                                   
                                  case 'url':
                                    return(
                                    <div key={`${index}`} className=" mb-3">
                                    <label className="col-form-label" htmlFor="basic-default-name">{field.name}{ suffix }</label>
                                    <div className="col-sm-10">
                                    <div className="input-group input-group-merge">
                                            {/* <span className="input-group-text" id="basic-addon34">https://abc.com/</span> */}
                                            <input 
                                              type="url" 
                                              className="form-control" 
                                              id="basic-url3" 
                                              aria-describedby="basic-addon34"
                                              placeholder={'john doe'}
                                              aria-label="john.doe"
                                              required={field.required || true}
                                              // value={val?.value }
                                              onChange={(payload)=>onChange(formattedField, payload.target.value)}
                                            />
                                          </div> 
                                    </div>
                                  </div>
                                      )
                           
                                  case 'number':
                                    return(
                                      <div key={`${index}`} className=" mb-3">
                                      <label className="col-form-label" htmlFor="basic-default-name">{field.name}{ suffix }</label>
                                      <div className="col-sm-10">
                                        <input
                                        type={'number'}
                                        className="form-control" 
                                        id="basic-default-name" 
                                        placeholder={field?.placeholder ?  field.name : field.type}
                                        aria-label="00"
                                        required={field.required}
                                        // value={val?.value }
                                        onChange={(payload)=>{onChange(formattedField, payload.target.value)}} 
                                          />
                                      </div>
                                    </div>
                                      )
                                   
                                  case 'verification':
                                    return(
                                      <div key={`${index}`} className=" mb-3">
                                      <label className="col-form-label" htmlFor="basic-default-name">{field.name}{ suffix }</label>
                                      <div className="col-sm-10">
                                        <input
                                        type={'number'}
                                        className="form-control" 
                                        id="basic-default-name"
                                        disabled={true} 
                                        placeholder={field?.placeholder ?  field.name : field.type}
                                        aria-label="john.doe"
                                        required={field.required}
                                        // value={val?.value }
                                        onChange={(payload)=>{onChange(formattedField, payload.target.value)}} 
                                          />
                                      </div>
                                    </div>
                                      )
                                   
                                  case 'radiobutton':
                                    return(
                                      <div key={`${index}`} className=" mb-3">
                                          <label className="col-form-label" htmlFor="basic-default-name">{field.name}{ suffix }</label>
                                          <div className="col-sm-10">
                                            {
                                                field.options.map((option, i) => {
                                                  console.log("option", option)
                                                    return (
                                                    <div key={i} className="form-check mt-3">
                                                      <input 
                                                        name="default-radio-1" 
                                                        className="form-check-input" 
                                                        type="radio"
                                                        required={field.required}
                                                        // value={val?.value }
                                                        onChange={(payload)=>onChange(formattedField,  option)} 
                                                      />
                                                      <label className="form-check-label" htmlFor="defaultRadio1"> {option} </label>
                                                    </div>
                                                  )
                                                })
                                              }  
                                            </div>
                                      </div>
                                      )
                           
                                  case 'multiselect':
                                    return(
                                      <div key={`${index}`} className=" mb-3">
                                          <label className="col-form-label" htmlFor="basic-default-name">{field.name}{ suffix }</label>
                                          <div className="col-sm-10">
                                          <div className="col-md">
                                              <small className="text-light fw-semibold">Select one or more options</small>
                                              {
                                                field.options.map((option, idx) => {
                                                  idx++
                                                    return (
                                                    <div className="form-check mt-3">
                                                      <input required={field.required}  className="form-check-input" onChange={(payload)=>onChange(formattedField,  idx)}  type="checkbox"  id="defaultCheck1"/>
                                                      <label className="form-check-label" htmlFor="defaultCheck1"> {option} </label>
                                                    </div>
                                                  )
                                                })
                                              } 
                                            </div>
                                          </div>
                                      </div>
                                      )
                           
                                  case 'rating':
                                    return(
                                      <div key={`${index}`} className=" mb-3">
                                        <label className="col-form-label" htmlFor="basic-default-name">{field.name}{ suffix }</label>
                                        <div className="col-sm-10">
                                        <Star
                                            onChange={(payload)=>onChange(formattedField, payload)}
                                            // onChange={(payload)=>console.log("payload", payload)}
                                            fraction={2}
                                            shape={'fat'} 
                                          /> 
                                        </div>
                                    </div>
                                      )
                           
                                  case 'email':
                                    return(
                                      <div key={`${index}`} className=" mb-3">
                                        <label className="col-form-label" htmlFor="basic-default-name">{field.name}{ suffix }</label>
                                        <div className="col-sm-10">
                                                <div className="input-group input-group-merge">
                                                  <input 
                                                  type={field.type}
                                                  id="basic-default-email" 
                                                  className="form-control" 
                                                  // placeholder={  "john.doe"} 
                                                  aria-label="john.doe"
                                                  required={field.required}
                                                  // value={val?.value }
                                                  onChange={(payload)=>onChange(formattedField, payload.target.value)} 
                                                  aria-describedby="basic-default-email2"/>
                                                  {/* <span className="input-group-text" id="basic-default-email2">john.doe@example.com</span> */}
                                                </div>
                                                <div className="form-text">You can use letters, numbers &amp; periods</div>
                                              </div> 
                                          
                                        
                                    </div>
                                      )
                           
                                  case 'longtext':
                                    return(
                                      <div key={`${index}`} className=" mb-3">
                                        <label className="  col-form-label" htmlFor="basic-default-name">{field.name}{ suffix }</label>
                                            <div className="col-sm-10">
                                            <textarea 
                                              id="basic-default-message" 
                                              className="form-control" 
                                              placeholder={field.placeholder || "Hi, Do you have a moment to talk Joe?" }
                                              aria-label="Hi, Do you have a moment to talk Joe?" 
                                              aria-describedby="basic-icon-default-message2"
                                              required={field.required}
                                              // value={val?.value }
                                              onChange={(payload)=>onChange(formattedField, payload.target.value)}
                                              >
                                              </textarea>
                                          </div> 
                          
                                      </div>
                                      
                                      )
                           
                                  case 'richtext':
                                    return(
                                      <div key={`${index}`} className=" mb-3">
                                        <label className="  col-form-label" htmlFor="basic-default-name">{field.name}{ suffix }</label>
                                            <div className="col-sm-10">
                                            <ReactQuill theme="snow"  onChange={(payload)=>onChange(formattedField, payload)} />
                                          </div> 
                          
                                      </div>
                                      
                                      )
                                   
                                  case 'payment':
                                    return(
                                      <div key={`${index}`} className=" mb-3">
                                      <label className=" col-form-label" htmlFor="basic-default-name">{field.name}{ suffix }</label>
                                      <div className="col-sm-10">
                                        <input
                                        type={'number'}
                                        className="form-control" 
                                        id="basic-default-name" 
                                        placeholder={field?.placeholder ?  field.name : field.type}
                                        aria-label="john.doe"
                                        required={field.required}
                                        // value={val?.value }
                                        onChange={(payload)=>{onChange(formattedField, payload.target.value)}} 
                                          />
                                      </div>
                                    </div>
                                      )
                           
                                  case 'select':
                                    return(
                                      <div key={`${index}`} className=" mb-3">
                                      <label className="  col-form-label" htmlFor="basic-default-name">{field.name}{ suffix }</label>
                                      <div className="col-sm-10">
                                      <select required={field.required}  
                                      // selected={val?.value}
                                      onChange={(event)=>{
                                        return onChange(formattedField, event.nativeEvent.target.value)
                                        }}  
                                          className="col-sm-10 form-select"  id="exampleFormControlSelect1" aria-label="Default select example">
                                            <option selected="" disabled={true}>{field.placeholder || 'Select option' }</option>
                                            {
                                                field.options.map((value, idx) => {
                                                    return ( 
                                                      <option  key={idx} value={value}>{ value }</option>
                                                  )
                                                })
                                              } 
                                          </select>
                                      </div>
                                    </div>
                                    )
                           
                                  case 'documents':
                                    return(
                                      <div key={`${index}`} className=" mb-3">
                                      <label className="col col-form-label" htmlFor="basic-default-name">{field.name}{ suffix }</label>
                                      <div className="col-sm-10">
                                      <div className="input-group">
                                      <input
                                            type="file"
                                            id="upload"
                                            required={field.required}
                                            className="account-file-input form-control"
                                            // hidden
                                            name="fileurl"
                                            // accept="image/png, image/jpeg, image/jpg"
                                            onChange={(e)=>{ handleFileuploadChange(formattedField, e) }}
                                        />
                                            {/* <input type="file" required={field.required}  onChange={(payload)=>onChange(formattedField, payload.target[0])} className="form-control" id="inputGroupFile02"/> */}
                                            <label className="input-group-text" htmlFor="inputGroupFile02">Upload</label>
                                          </div> 
                                      </div>
                                    </div> 
                                    )
                           
                                  case 'esign':
                                    return(
                                      <div key={`${index}`} className=" mb-3">
                                      <label className="col  col-form-label"  htmlFor="basic-default-name">{field.name}{ suffix }</label>
                                      <div className="col-sm-10">
                                          <div className="input-group">
                                            <div className="w-100 align-items-center justify-content-between">
                                                {trimmedDataURL ? (
                                                    <img className={styles.sigImage} src={trimmedDataURL} alt="Signature" />
                                                  ) : null}
                          
                                            </div>
                                          <div className={styles.sigFullcontainer}>
                                            <div className={styles.sigContainer}>
                                              <SignaturePad
                                                canvasProps={{ className: styles.sigPad }}
                                                ref={sigPadRef}
                                              />
                                            </div>
                                                <div class="d-flex w-100 align-items-center justify-content-between gap-2 mt-3">
                                                  <div className="col-sm-10">
                                                    <div onClick={clear} className="btn btn-danger">Clear</div>
                                                  </div>
                                                  <div className="col-sm-10">
                                                    <div  onClick={()=>trim(formattedField)} style={{backgroundColor:organization?.primaryColor, borderColor:organization?.primaryColor}} className="btn btn-primary">Sign</div>
                                                  </div>
                                              </div>
                                            
                                          </div>
                                          </div> 
                                      </div>
                                    </div> 
                                    )
                                   
                          
                                  // eslint-disable-next-line no-lone-blocks
                                  default: return (<></>)
                                    // break;
                                }
                          
                                // return  data 
                                
                              })
                            }


                              <div className="row justify-content-end">
                                <div className="">
                                  <button type="submit" style={{backgroundColor:organization?.primaryColor, borderColor:organization?.primaryColor}} className="btn btn-primary">Submit</button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>

                      </div>
                      {/* <div className="col-3 col-md-4 col-12 mb-4">
                      <div className="card">
                      <img className="card-img-top" src={require('../assets/img/edata.png')} alt="Card cap" /> 
                         <div className="card-body">
                          <h5 className="card-title">Introducing E-DATA</h5>
                          <p className="card-text">
                            Sign Up now to reduce operational cost by 75% and Unlock the Full Potential of customer data. 
                          </p>
                          <a href={'https://loop.com.na'} rel="noreferrer" style={{backgroundColor: "#115799", borderColor:  "#115799" }}  className="btn btn-primary">Get started now</a>
                        </div>
                      </div>
                    </div> */}
                </div>
                }

                {
                  forms.length > 1 &&
                  <div className="">

                  <div className="col-xxl">
                    <div className="mb-4"> 
                      <div className="order-2 mb-4">
                    <div className=" h-100">
                      <div  className="card-header d-flex align-items-center justify-content-between" style={{ paddingLeft: 0}}>
                        <h3 style={{textTransform: "uppercase"}} className="card-title m-0 me-2">{organization && organization?.name ? `More from ${organization && organization?.name}` : 'Loading..'}</h3>
                        <div className="dropdown">
                          <button
                            className="btn p-0"
                            type="button"
                            id="transactionID"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="bx bx-dots-vertical-rounded"></i>
                          </button>
                          <div className="dropdown-menu dropdown-menu-end" aria-labelledby="transactionID">
                            <Link className="dropdown-item" to={"/"}>Last 28 Days</Link>
                            <Link className="dropdown-item" to={"/"}>Last Month</Link>
                            <Link className="dropdown-item" to="/">Last Year</Link>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-5">
                          {
                            forms && forms.filter((f)=> { return f.id !==form.id}).map((form, idx) => {
                              idx++
                              let content = "Loading"
                              if(idx<15){
                                content = <div className="col-md-6 col-lg-4" onClick={()=>dispatch(fetchForms(form.uid))} key={`${idx}`} style={{cursor:'pointer'}}>
                                <div className="card mb-4">
                                  <div className="card-body">
                                    <div className="card-title header-elements">
                                      <h5 className="m-0 me-2">{form && form?.name}</h5>
                                      <div className="card-title-elements ms-auto">
                                        <span className="badge bg-label-primary rounded-pill">10 Submited</span>
                                      </div>
                                    </div>
                                    <p className="card-text">{form && form?.description}</p>
                                  </div>
                                </div> 
                              </div>  
                              }
                              return content 
                            })
                          } 
                      </div>
                    </div>
                  </div>
                    </div>
                  </div>
                  </div>
                  
                }
              </div>
                </div> 
              </div>
            </div> 
     </div>
  );
}

export default Form;

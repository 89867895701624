export default class Strings {
    static CLOUD_API            = process.env.NODE_ENV === "development" ? "http://0.0.0.0:5000/api/" : `https://api-prod.loop.com.na/api/`
    static QUEUE_API            = process.env.NODE_ENV === "development" ? "http://0.0.0.0:4000/" : `http://46.101.114.93/`
    static SOCKET_SERVER        = process.env.NODE_ENV === "development" ? "http://0.0.0.0:3000" :  "https://api.loop.com.na"
    static LOOP_SERVER        = process.env.NODE_ENV === "development" ? "http://0.0.0.0:3000" :  "https://api.loop.com.na" 

    static KAFKA_SERVER         = ""
    static ELASTIC_SERVER       = ""
    static REDIS_SERVER         = ""

    // KEYS
    static SENDGRID_KEY         = ""
    static TWILLIO_KEY          = ""
}

import { storage } from "../utils/firebase";
import { getStorage, ref, getMetadata, getDownloadURL, uploadBytesResumable } from "firebase/storage";
const dayjs = require('dayjs')
var now = dayjs().format()
var created = dayjs(now).unix()

const { v1: uuidv1 } = require('uuid');
const uploadImage = async (dataCollected, form ) => {
    return new Promise(async (resolve, reject) => {
       await form && form?.fields.forEach( async (el, idx) => {
        var findField = dataCollected && dataCollected.find((field => field.uid===el.uid.replace(/-/g, '_') ))
         if(el?.type === `documents`){
           if(findField){ 
              const bucketName = 'rootStorage/uploads/'
              const file = findField?.value
              const ext = file.name.split('.').pop() 
              const fileName = uuidv1() + "." + ext 
              const storageRef = ref(storage, `${bucketName}/${fileName}`);
               const uploadTask = uploadBytesResumable(storageRef, file);

              uploadTask.on("state_changed",
                (snapshot) => {
                  const progress =
                    Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                 },
                (error) => {
                  alert(error);
                },
                () => {
                  getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                     resolve([{uid:findField?.uid, value:downloadURL }] )  
                   });
                }
              ); 
            }
           }
           else if( el?.type === `esign`){
            if(findField){ 

              async function fetchBlobData(blobUrl) {
                const response = await fetch(blobUrl);
                const blobData = await response.blob();
                return blobData;
              }
             
               const bucketName = 'rootStorage/uploads/'
               const fileName = uuidv1() + ".jpg"
              const blobData = await fetchBlobData(findField?.value);
               const storageRef = ref(storage, `${bucketName}/${fileName}`);
                  const uploadTask = uploadBytesResumable(storageRef, blobData);
  
                uploadTask.on("state_changed",
                  (snapshot) => {
                    const progress =
                      Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                   },
                  (error) => {
                    alert(error);
                  },
                  () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                       resolve([{uid:findField?.uid, value:downloadURL }] )  
                     });
                  }
                ); 
              }
           }
         })
    
     });
};

export  { uploadImage } 
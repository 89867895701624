// AUTH
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const START_LOADING = "START_LOADING";
export const IS_LOADING = "IS_LOADING";
export const STOP_LOADING = "STOP_LOADING";


// ORGANIZATION : () => {}
export const ON_ORGANIZATION_START = "ON_ORGANIZATION_START";
export const ON_ORGANIZATION_SUCCESS = "ON_ORGANIZATION_SUCCESS";
export const ON_ORGANIZATION_FAIL = "ON_ORGANIZATION_FAIL";
export const ON_ORGANIZATION_UPDATE = "ON_ORGANIZATION_UPDATE";


// USER : () => {}
export const ON_USER_START = "ON_USER_START";
export const ON_USER_SUCCESS = "ON_USER_SUCCESS";
export const ON_USER_FAIL = "ON_USER_FAIL";
export const ON_USER_UPDATE = "ON_USER_UPDATE";
export const ON_USER_DELETE = "ON_USER_DELETE";


// USERS : () => {}
export const ON_USERS_START = "ON_USERS_START";
export const ON_USERS_SUCCESS = "ON_USERS_SUCCESS";
export const ON_USERS_FAIL = "ON_USERS_FAIL";
export const ON_USERS_UPDATE = "ON_USERS_UPDATE";
export const ON_USERS_DELETE = "ON_USERS_DELETE";

// BRANCHES : () => {}
export const ON_BRANCH_START = "ON_BRANCH_START";
export const ON_BRANCH_SUCCESS = "ON_BRANCH_SUCCESS";
export const ON_BRANCH_FAIL = "ON_BRANCH_FAIL";
export const ON_BRANCH_UPDATE = "ON_BRANCH_UPDATE";
export const ON_BRANCH_DELETE = "ON_BRANCH_DELETE";


// EMAILS : () => {}
export const ON_EMAILS_START = "ON_EMAILS_START";
export const ON_EMAILS_SUCCESS = "ON_EMAILS_SUCCESS";
export const ON_EMAILS_FAIL = "ON_EMAILS_FAIL";
export const ON_EMAILS_UPDATE = "ON_EMAILS_UPDATE";
export const ON_EMAILS_DELETE = "ON_EMAILS_DELETE";


// SMS : () => {}
export const ON_SMS_START = "ON_SMS_START";
export const ON_SMS_SUCCESS = "ON_SMS_SUCCESS";
export const ON_SMS_FAIL = "ON_SMS_FAIL";
export const ON_SMS_UPDATE = "ON_SMS_UPDATE";
export const ON_SMS_DELETE = "ON_SMS_DELETE";

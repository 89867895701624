import React  from 'react'
import { Link } from 'react-router-dom'
 
const Example = () => {
     
  return ( 
    <div class="content-wrapper">
 
    <div class="container-xxl flex-grow-1 container-p-y">
      <h4 class="fw-bold py-3 mb-4"><span class="text-muted fw-light">E data /</span> We are curious, how do you use e forms</h4>

      <p>
        Giving you the power and control to collect data from anywhere anytime?, cant see your desired e-form, contact us at hello@loop.com.na 
       </p>

       <div class="d-flex flex-wrap" id="icons-container">
        <div class="card icon-card cursor-pointer text-center mb-4 mx-2">
          <div class="card-body">
            <i class="bx bx-edit-alt mb-2"></i>
            <p class="icon-name text-capitalize text-truncate mb-0">Contact Forms</p>
          </div>
        </div>
        <div class="card icon-card cursor-pointer text-center mb-4 mx-2">
          <div class="card-body">
            <i class="bx bx-calendar-alt mb-2"></i>
            <p class="icon-name text-capitalize text-truncate mb-0">Event registration</p>
          </div>
        </div>
        <div class="card icon-card cursor-pointer text-center mb-4 mx-2">
          <div class="card-body">
            <i class="bx bx-smile mb-2"></i>
            <p class="icon-name text-capitalize text-truncate mb-0">Surveys/Feedback</p>
          </div>
        </div>
        <div class="card icon-card cursor-pointer text-center mb-4 mx-2">
          <div class="card-body">
            <i class="bx bx-data mb-2"></i>
            <p class="icon-name text-capitalize text-truncate mb-0">Data collection</p>
          </div>
        </div>
        <div class="card icon-card cursor-pointer text-center mb-4 mx-2">
          <div class="card-body">
            <i class="bx bx-data mb-2"></i>
            <p class="icon-name text-capitalize text-truncate mb-0">Workflow automation</p>
          </div>
        </div>
        <div class="card icon-card cursor-pointer text-center mb-4 mx-2">
          <div class="card-body">
            <i class="bx bxl-etsy mb-2"></i>
            <p class="icon-name text-capitalize text-truncate mb-0">Lead generation</p>
          </div>
        </div>
        <div class="card icon-card cursor-pointer text-center mb-4 mx-2">
          <div class="card-body">
            <i class="bx bx-credit-card mb-2"></i>
            <p class="icon-name text-capitalize text-truncate mb-0">Payment Collection</p>
          </div>
        </div>
        <div class="card icon-card cursor-pointer text-center mb-4 mx-2">
          <div class="card-body">
            <i class="bx bx-id-card mb-2"></i>
            <p class="icon-name text-capitalize text-truncate mb-0">Verifications</p>
          </div>
        </div>
        <div class="card icon-card cursor-pointer text-center mb-4 mx-2">
          <div class="card-body">
            <i class="bx bx-grid mb-2"></i>
            <p class="icon-name text-capitalize text-truncate mb-0">Applications</p>
          </div>
        </div>
        <div class="card icon-card cursor-pointer text-center mb-4 mx-2">
          <div class="card-body">
            <i class="bx bx-circle mb-2"></i>
            <p class="icon-name text-capitalize text-truncate mb-0">Other</p>
          </div>
        </div>
        
     
      </div>

       <div class="d-flex justify-content-center mx-auto gap-3">
        <Link to="/demo" class="btn btn-primary">Looking for something else</Link>
        {/* <Link to="/" target="_blank" class="btn btn-primary">View All solutions</Link>
        <Link to="/documentation" class="btn btn-primary" target="_blank">How to crete e forms?</Link> */}
      </div>
    </div>
 
    
 
    <div class="content-backdrop fade"></div>
  </div> 
  );
}

export default Example;

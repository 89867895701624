/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { Link, NavLink} from 'react-router-dom'
import { useGeolocated } from "react-geolocated";
import { useDispatch, useSelector } from 'react-redux'
const Layout = (props) => {

  const dispatch = useDispatch()
  var  { alert }  =  useSelector((state) => state.organization);
  const [showMenu, setShowMenu] = React.useState(false) 
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false,
            },
            userDecisionTimeout: 5000,
        }, async () =>{

        });
        React.useEffect(()=>{
          // updateLocation() 
        },[])
        
        const updateLocation = () => {
          dispatch({type:"LOCATION", payload:coords})

        }
         
         const onMenuToggle = () => setShowMenu(!showMenu)
         const hideAlert = () => dispatch({ type: 'HIDE_NOTIFICATION' })
         const collapse = `collapsed`

  return (
    <div className="layout-wrapper  layout-content-navbar layout-without-menu">
      <div className="layout-container">
        <div className="layout-page"> 
        <nav className="navbar navbar-expand-lg bg-white">
                  <div className="container-fluid container">
                    <NavLink className="navbar-brand" to="/">
                    <img src={require('../assets/img/loop.png')} alt={''} style={{width:170}}/> 
                    </NavLink>
                    <button onClick={onMenuToggle} className={`navbar-toggler ${ showMenu ? collapse : ''}` } type="button" data-bs-toggle="collapse" data-bs-target="#navbar-ex-4">
                      <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className= {`collapse navbar-collapse ${ showMenu ? `show` : ''}` }  id="navbar-ex-4" >
                      <div className="navbar-nav me-auto">
                        {/* <NavLink className="nav-item nav-link active" to="/">CX</NavLink> */}
                        {/* <NavLink className="nav-item nav-link " to="/">Verifications</NavLink> */}
                        <NavLink onClick={onMenuToggle} className="nav-item nav-link" to="/e-forms">Forms</NavLink>
                        {/* <NavLink onClick={onMenuToggle} className="nav-item nav-link" to="/demo">Demo</NavLink> */}
                        {/* <NavLink onClick={onMenuToggle} className="nav-item nav-link " to="/solutions">Solutions</NavLink> */}
                        {/* <NavLink className="nav-item nav-link " to="/pricing">Pricing</NavLink> */}
                        {/* <NavLink className="nav-item nav-link" to="/about">Developers</NavLink> */}
                        {/* <NavLink className="nav-item nav-link" to="/about">More</NavLink> */}
                        {/* <NavLink className="nav-item nav-link" to="/about">Support</NavLink> */}
                        <NavLink onClick={onMenuToggle} className="nav-item nav-link " to="/faqs">QA's</NavLink>
                       </div>
                        <NavLink   className="btn btn-outline-primary" style={{marginRight:16}} to="/demo">Request demo</NavLink>
                        <a   className="btn btn-outline-primary" style={{marginRight:16}} target={'_blank'} href={'https://console.loop.com.na/login'}>Sign in</a>
                       {/* <form className="d-flex inline-spacing" _lpchecked="1" style={{marginLeft:8}}>
                        <div className="input-group">
                          <span className="input-group-text"><i className="tf-icons bx bx-search"></i></span>
                          <input type="text" className="form-control" placeholder="Search..."/>
                        </div>
                      </form> */}
                    </div>
                  </div>
                </nav>

          <div className="content-wrapper">
             <div className=" container-xxl flex-grow-1 container-p-y">
            {
                alert && alert.show ?
                  <div style={{position:"absolute", top:32, right:32}} className={`bs-toast toast fade show } position-absolute top-3 end-3 bg-${alert.type}`} role="alert" aria-live="assertive" aria-atomic="true">
                  <div className="toast-header">
                                <i className="bx bx-bell me-2"></i>
                                <div className="me-auto fw-semibold"> {alert?.title}</div>
                                 <div style={{cursor:"pointer"}} onClick={hideAlert} className="btn-close" data-bs-dismiss="toast" aria-label="Close"></div>
                              </div>
                    <div className="toast-body">
                      {alert?.message}
                    </div>
                  </div>
                : null
              
              }
              <div className=" layout-demo-wrapper"> 
                  { props.children }  
               </div>
            </div>

            <footer className="content-footer footer bg-footer-theme">
              <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                <div className="mb-2 mb-md-0">
                  © {new Date().getFullYear()}
                  , a product of  &nbsp; 
                  <a href={'https://loop.com.na'} target="_blank" className="footer-link fw-bolder"> Loop technologies</a>
                </div>
                <div>
                  <Link to="/terms-privacy" className="footer-link me-4" target="_blank">Terms and privacy policy</Link>
                  <a href="https://loop.com.na/#work-process" target="_blank" className="footer-link me-4">Solutions</a>

                  <Link to="/" target="_blank" className="footer-link me-4">Documentation</Link>

                  <Link to="/" className="footer-link me-4">Support</Link>
                </div>
              </div>
            </footer>

            <div className="content-backdrop fade"></div>
          </div>
        </div>
      </div> 
    </div>
   );
};

export default (Layout);

/* eslint-disable import/no-anonymous-default-export */
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,


  ON_USER_START,
  ON_USER_SUCCESS,
  ON_USER_FAIL,
  ON_USER_UPDATE,
  ON_USER_DELETE,
} from "../actions/types";
 
var user = JSON.parse(localStorage.getItem("user"));

user = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

  
  var initialState = {
    user:user,
    userData:null,
    settings:{},
    test:0  
  }


  // user reducer
export default function (state = initialState, action) {
  const { type, payload } = action;
  // AUTH
  // Register 
   switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };

      // Login
    case LOGIN_SUCCESS:
      let { user } = payload &&  payload.user
      let { metadata, uid, email, phoneNumber, accessToken } =  user
      
      return {
        ...state,
        isLoggedIn: true,
        user: { metadata, uid, email, phoneNumber, accessToken },
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case 'LOGOUT':
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        userData: null
      };


      //   // USER
    case ON_USER_SUCCESS:
       return {
        ...state,
        userData: payload,
         };
    // case ON_USER_UPDATE:
    //   return {
    //     ...state,
    //     payload: state.users.find( user => user.id === payload.id )
    //   };

    // case ON_USER_DELETE:
    //   return {
    //     ...state,
    //     payload: state.users.filter( user => user.id !== payload.id )
    //   };

    // case ON_USER_FAIL:
    //   return {
    //     ...state
    //   };



    default:
      return state;
  }
}
